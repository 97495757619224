<template>
  <div class="p-16 sm:p-24">
    <div class="flex justify-between mb-8">
      <div class="flex type-headline-sm">
        <div
          class="w-24 h-24 bg-success-primary flex justify-center items-center text-grey200 mr-12 rounded-full flex-shrink-0"
        >
          <fa class="text-tiny" :icon="['fas', 'check']" />
        </div>
        <div v-if="updated">
          {{ $lang('shoppingCartResources',
                   'itemUpdatedHeading') }}
        </div>
        <div v-else>
          {{
            $lang(
              'shoppingCartResources',
              subscription ? 'subscriptionItemAddedHeading' : 'itemAddedHeading'
            )
          }}
        </div>
      </div>
      <div class="select-none cursor-pointer" @click="$emit('close-overlay')">
        <img
          src="/zynicons/close.svg"
          class="h-24 w-24"
          height="24"
          width="24"
        >
      </div>
    </div>
    <div class="flex mb-16 pl-36">
      <div class="flex-1">
        <div class="type-sm">
          {{ data.name }}
        </div>
      </div>
    </div>
    <div class="md:flex justify-center md:pl-12">
      <Button
        :is-block="true"
        :link-url="checkoutUrl"
        :size="Sizes.md"
        :theme="Themes.dark"
        :small-padding="true"
        class="mb-12 md:mr-4 md:mb-0 w-full"
        :text="$lang('shoppingCartResources', 'proceedToCheckout')"
        @click="$emit('close-overlay')"
      />
      <Button
        :is-block="true"
        :size="Sizes.md"
        :theme="Themes.secondary"
        :small-padding="true"
        class="md:ml-4"
        :text="$lang('shoppingCartResources', 'continueShopping')"
        @click="$emit('close-overlay')"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import { Sizes, Themes } from '../constants/button-layouts';
import type { ICartItem } from '~/models/api-types';

const props = defineProps<{
  data: ICartItem,
  subscription?: boolean,
  updated?: boolean
}>();

const cartStore = useCartStore();
const checkoutUrl = computed(() => {
  const checkoutUrl = props.subscription
    ? cartStore.subscriptionCart?.checkoutUrl || '/'
    : cartStore.cart.checkoutUrl || '/';
  return checkoutUrl;
});
</script>
<style scoped>
.text-tiny {
  font-size: 12px !important;
}
</style>
